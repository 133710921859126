<template>
  <div>
    <content-block>
    库存合计： {{count}}
    </content-block>
    <table-with-pagination
      :tableData="tableData"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { getStockCount, getStockList } from '@/api/spareParts';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import contentBlock from '../../../../components/common/layout/contentBlock.vue';

export default {
  components: { contentBlock },
  name: 'STOCK_TABLE',
  mixins: [TablePaginationMixin],
  props: {
    sparePartId: {
      type: String,
    },
  },
  watch: {
    sparePartId: {
      handler () {
        this.getTableList();
      },
      deep: true,
    },
  },
  data () {
    return {
      count: 0,
      tableData: [],
      columnList: [
        {
          tooltip: true,
          label: '仓库',
          minWidth: '160px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: '仓库类型',
          minWidth: '150px',
          prop: 'warehouseTypeName',
        },
        {
          tooltip: true,
          label: '数量',
          minWidth: '150px',
          prop: 'quantity',
        },
      ],
    };
  },
  methods: {
    getTableList () {
      if (!this.sparePartId) {
        return;
      }

      getStockCount({
        sparePartId: this.sparePartId,
      }).then((res) => {
        this.count = res.body;
      });

      getStockList({
        sparePartId: this.sparePartId,
        pageFlag: true,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      }).then((res) => {
        this.tableData = res.body.list ?? [];
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
