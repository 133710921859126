<template>
  <div>
    <content-block>
      <content-title>
        备件详情
        <el-button v-auth="'sparePart:update'" style="margin-left: 12px;" type="primary" @click="openDialog">编辑</el-button>
      </content-title>
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="备件名称">{{
          sparePartDetail.name
        }}</el-descriptions-item>
        <el-descriptions-item label="备件类型">{{
          sparePartDetail.sparePartTypeName
        }}</el-descriptions-item>
        <el-descriptions-item label="规格型号">{{
          sparePartDetail.spec
        }}</el-descriptions-item>
        <el-descriptions-item label="备件编码">{{
          sparePartDetail.code
        }}</el-descriptions-item>
        <el-descriptions-item label="单位">{{
          sparePartDetail.unit
        }}</el-descriptions-item>
        <el-descriptions-item label="是否医疗件">{{
          sparePartDetail.isMedicalStr
        }}</el-descriptions-item>
        <el-descriptions-item v-if="sparePartDetail.isMedical" label="医疗件标准分类">{{
          sparePartDetail.medicalStandardClassificationName
        }}</el-descriptions-item>
        <el-descriptions-item v-if="sparePartDetail.isMedical" label="注册/备案凭证号">{{
          sparePartDetail.registerCode
        }}</el-descriptions-item>
        <el-descriptions-item label="所属设备">{{
          formatDeviceName(sparePartDetail.device)
        }}</el-descriptions-item>
        <el-descriptions-item label="出库价">{{
          sparePartDetail.deliveryPrice
        }}</el-descriptions-item>
        <el-descriptions-item label="销售价" :span="2">{{
          sparePartDetail.salePrice
        }}</el-descriptions-item>
        <el-descriptions-item label="图片" :span="3">
          <upload
            size="mini"
            :disabled="true"
            :value="sparePartDetail.picture || []"
            :maxSize="5"
            :limit="10"
          />
        </el-descriptions-item>
        <el-descriptions-item label="详情说明">{{
          sparePartDetail.detailedInformation
        }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block>
      <div class="order_progress_sum d-flex flex-row justify-content-between">
        <div class="flex-fill d-flex flex-column justify-content-around">
          <div class="d-flex flex-row align-items-center">
            <span class="order_progress_status">{{
              logInfo[0]
                ? `${logInfo[0].content}`
                : "暂无数据"
            }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-row align-items-center cursor-pointer"
          @click="drawerVisible = true"
        >
          <span>查看动态信息</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </content-block>
    <content-block>
      <el-tabs class="content_block" v-model="activeName">
        <el-tab-pane label="使用记录" name="record">
          <records :sparePartId="sparePartId" />
        </el-tab-pane>
        <el-tab-pane label="库存信息" name="stock">
          <stock :sparePartId="sparePartId" />
        </el-tab-pane>
      </el-tabs>
    </content-block>
    <bottom-panel>
      <el-button type="primary" plain class="mr-1" @click="handleClose"
        >返回</el-button
      >
    </bottom-panel>
    <el-drawer title="动态信息" :visible.sync="drawerVisible">
      <el-timeline class="content_block">
        <el-timeline-item
          v-for="(activity, index) in logInfo"
          :key="index"
          hide-timestamp
          :type="index === 0 ? 'primary' : ''"
          placement="top"
        >
          <div class="order_progress_item">
            <div>
              <span>{{ activity.content }}</span>
              {{ dateFormat(activity.operateTime) }}
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
    <el-dialog
      width="720px"
      title="编辑备件"
      :visible.sync="deviceDialogVisible"
    >
      <el-form
        label-width="120px"
        ref="deviceForm"
        :rules="deviceFormRules"
        :model="deviceForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="备件名称：" prop="name">
              <el-input
                v-model="deviceForm.name"
                readonly
                placeholder="请输入备件名称"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备件类型：" prop="sparePartTypeId">
              <SpareTypesSelectors :name="deviceForm.sparePartTypeName" @change="handleTypeChange" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="规格型号：" prop="spec">
              <el-input
                v-model="deviceForm.spec"
                readonly
                placeholder="请输入规格型号"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位：">
              <el-input
                v-model.trim="deviceForm.unit"
                clearable
                placeholder="请输入单位"
                :maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备件编码：">
              <el-input
                v-model.trim="deviceForm.code"
                clearable
                placeholder="请输入备件编码"
                :maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属设备：">
              <MultiDeviceSelector :devices="devices" @change="handleDeviceId" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否医疗件：" prop="isMedical">
              <el-select v-model="deviceForm.isMedical" placeholder="请选择">
                <el-option
                  v-for="t in medicalOptions"
                  :value="t.value"
                  :label="t.label"
                  :key="t.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出库价：" prop="deliveryPrice">
              <el-input-number
                :min="0.01"
                :precision="2"
                placeholder="请输入出库价"
                v-model.trim="deviceForm.deliveryPrice"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="销售价：" prop="salePrice">
              <el-input-number
                :min="0.01"
                :precision="2"
                placeholder="请输入销售价"
                v-model.trim="deviceForm.salePrice"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="deviceForm.isMedical === '1'">
            <el-form-item label="医疗件标准分类：" label-width="140px">
              <MedicalTree :name="deviceForm.medicalStandardClassificationName" @change="handleMedical" />
            </el-form-item>
        </el-row>
        <el-row v-show="deviceForm.isMedical === '1'">
            <el-form-item label="注册/备案凭证号：" label-width="140px">
              <el-input
                v-model.trim="deviceForm.registerCode"
                clearable
                placeholder="请输入注册/备案凭证号"
                :maxlength="50"
              ></el-input>
            </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="图片：">
            <Upload
              v-model.trim="fileLists"
              label="品牌LOGO"
              @success="handleUpload"
              @remove="handleRemove"
              :maxSize="5"
              size="small"
              :limit="10"
            />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="详情说明：">
            <el-input
              v-model.trim="deviceForm.detailedInformation"
              clearable
              placeholder="请输入详情说明"
              type="textarea"
              :maxlength="800"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="deviceDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSaveDevice">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSpareDeviceDetail, updateSpareDevice,
  log,
} from '@/api/spareParts';
import Stock from './components/stock';
import Records from './components/records';
import SpareTypesSelectors from '@/components/spareTypeSlelector';
import MultiDeviceSelector from '@/components/multiDeviceSelector';
import Upload from '@/components/common/upload';
import MedicalTree from '@/components/medicalTree';

import _ from 'lodash';

export default {
  name: 'SPAREPART_DETAIL',
  components: {
    Stock,
    Records,
    SpareTypesSelectors,
    MultiDeviceSelector,
    Upload,
    MedicalTree,
  },
  data () {
    return {
      sparePartDetail: {},
      drawerVisible: false,
      logInfo: [],
      activeName: 'record',
      sparePartId: '',
      medicalOptions: [
        {
          label: '是',
          value: '1',
        },
        {
          label: '否',
          value: '0',
        },
      ],
      deviceForm: {
        name: '',
        sparePartTypeId: '',
        sparePartTypeName: '',
        spec: '',
        unit: '',
        code: '',
        device: [],
        isMedical: '',
        deliveryPrice: '',
        salePrice: '',
        medicalStandardClassificationId: '',
        medicalStandardClassificationName: '',
        registerCode: '',
        picture: [],
        detailedInformation: '',
      },
      deviceDialogVisible: false,
      deviceFormRules: {
        name: [
          { required: true, message: '请输入备件名称', trigger: 'blur' },
        ],
        sparePartTypeId: [
          { required: true, message: '请选择备件类型', trigger: 'change' },
        ],
        spec: [
          { required: true, message: '请输入规格型号', trigger: 'blur' },
        ],
        isMedical: [
          { required: true, message: '请选择是否医疗件', trigger: 'change' },
        ],
        deliveryPrice: [
          { required: true, message: '请输入出库价', trigger: 'blur' },
        ],
        salePrice: [
          { required: true, message: '请输入销售价', trigger: 'blur' },
        ],
      },
      fileLists: [],
      devices: [],
      dateFormat: this.$options.filters['date-format'],
    };
  },
  methods: {
    handleClose () {
      this.$router.go(-1);
    },
    openDialog () {
      this.deviceDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.deviceForm.resetFields();
        this.handleEditData();
      });
    },
    handleEditData () {
      this.deviceForm = _.cloneDeep(this.sparePartDetail);
      this.deviceForm.isMedical = this.deviceForm.isMedical + '';

      this.fileLists = [];
      if (this.sparePartDetail?.picture) {
        this.fileLists = this.sparePartDetail.picture.map((v) => ({
          url: v.url,
          annexName: v.attachName,
        }));
      }

      this.devices = this.deviceForm.device.map((v) => ({
        name: v.name,
        id: v.deviceId,
      }));
    },
    getSparePartDetail () {
      if (this.$route.query.id) {
        getSpareDeviceDetail({ id: this.$route.query.id }).then((res) => {
          this.sparePartDetail = res.body;
        });
      }
    },
    formatDeviceName (devices) {
      devices = (devices || []).map((v) => v.name);

      return devices.join(',');
    },
    getlog () {
      log({ id: this.$route.query.id }).then((res) => {
        this.logInfo = res.body || [];
      });
    },
    handleTypeChange (v) {
      this.deviceForm.sparePartTypeId = v.id;
      this.deviceForm.sparePartTypeName = v.name;
    },
    handleDeviceId (v) {
      this.deviceForm.device = v.map((i) => ({
        deviceId: i.id,
        name: i.name,
      }));
    },
    handleMedical (v) {
      this.deviceForm.medicalStandardClassificationId = v.id;
      this.deviceForm.medicalStandardClassificationName = v.name;
    },
    handleUpload (file) {
      this.fileLists.push(file);
    },
    handleRemove (file) {
      const index = this.fileLists.findIndex(v => v.uid === file.uid);

      this.fileLists.splice(index, 1);
    },
    handleSaveDevice () {
      this.$refs.deviceForm.validate((valid) => {
        if (valid) {
          const api = updateSpareDevice;

          let params = {
            ...this.deviceForm,
          };

          if (params.isMedical !== '1') {
            params.registerCode = '';
            params.medicalStandardClassificationId = '';
            params.medicalStandardClassificationName = '';
          }

          params.picture = this.fileLists.map(v => ({
            annexName: v.attachName,
            url: v.url,
          }));

          api(params).then(() => {
            this.deviceDialogVisible = false;
            this.$message({
              message: '编辑成功',
              type: 'success',
            });
            this.getSparePartDetail();
          });
        }
      });
    },
  },
  created () {
    this.sparePartId = this.$route.query.id;
    this.getSparePartDetail();
    this.getlog();
  },
};
</script>
<style lang="scss" scoped>
.order_progress_sum {
  width: 100%;
  height: 64px;
  background: rgba(36, 40, 50, 0.06);
  border-radius: 4px;
  color: rgba(36, 40, 50, 0.6);
  font-size: 12px;
  line-height: 1;
  padding: 12px;
  box-sizing: border-box;

  .order_progress_status {
    font-size: 16px;
    margin-right: 16px;
    color: rgba(36, 40, 50, 0.8);
  }
}

.order_progress_item {
  font-size: 12px;
  color: rgba(36, 40, 50, 0.7);
  line-height: 16px;
  vertical-align: middle;

  > div {
    margin-bottom: 8px;
    > span {
      font-size: 14px;
      font-weight: bold;
      margin-right: 12px;
    }
  }
}

.mt_item_cell {
  margin: -8px -10px;

  & > div + div {
    border-top: 1px solid #ebeef5;
  }

  & > div {
    line-height: 40px;

    & > div:first-child {
      width: 200px;
    }
    & > div:last-child {
      padding-left: 16px;
      border-left: 1px solid #ebeef5;
    }
  }
}
</style>
